import styles from "./GradientButton.module.css";

export default function GradientButton({ color, text, onHandleClick, link }) {
  return (
    <a href={link}>
      <button style={{ background: color }} className={styles.root} onClick={onHandleClick}>
        {text}
      </button>
    </a>
  );
}
