import { useParams, useNavigate } from 'react-router-dom';

export default function useContent(){
    const navigate = useNavigate();
    const {slide, content} = useParams();
    return(
        (callback) => {
            if (content === 'portfolio') callback([true, false]);
            else if(content === 'learnFromMe') callback([false, true]);
            else navigate(`/mediacenter/${slide}/portfolio`);
        }
    );
}