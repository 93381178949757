import styles from './MediaCenter.module.css';
import React, { useState } from 'react';
import { Footer, Header, Intro, ContentContainer } from '../../components';

export default function MediaCenter(props) {

    const [introPlayer, setIntroPlayer] = useState(null);
    const onChangePlayer = (player) => setIntroPlayer(player);

    return (
        <div className={styles.wrapper}>
            <div id="cursor"><img alt="cursor" /></div>

            <Header
                language={props.language}
                changeLanguage={props.changeLanguage}
                changePlayer={onChangePlayer}
                player={introPlayer} />

            <Intro
                language={props.language}
                changePlayer={onChangePlayer}
                player={introPlayer} />

            <ContentContainer language={props.language} />

            <Footer />
        </div>
    );
}