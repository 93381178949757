import styles from './ImmersiveVisuals.module.css';
import { useState, useLayoutEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Header, Footer, SaleArticle, GradientButton } from '../../components';
import { RU, EN } from './data.js';
import Arrow from './assets/images/Arrow.png';
import ArrowLeft from './assets/images/ArrowResultLeft.png';
import ArrowRight from './assets/images/ArrowResultRight.png';
import ArrowBlue from './assets/images/BlueArrow.png';
import Author from './assets/images/Author.png';
import AuthorRect from './assets/images/RectangleAuthor.png';
import Insta from './assets/images/InstIcon.svg';
import Subtract from './assets/images/Subtract.png';
import { observer } from '../../helpers/exports';

const onResultBody = (e) => {
  if (window.innerWidth > 700) {
    const block = document.querySelector('#resultBefore');
    const blockPosition = e.clientX - block.getBoundingClientRect().left;
    block.style.width = blockPosition + 'px';
    const draggerPosition =
      block.getBoundingClientRect().right - block.getBoundingClientRect().left;
    document.querySelector('#resultDragger').style.left =
      draggerPosition - 28 + 'px';
  }
};

export default function ImmersiveVisuals({ language, changeLanguage }) {
  const [planVissibility, setPlanVissibility] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const navigate = useNavigate();
  const anchor = useRef();

  const setAnchor = (elem) => {
    if (!anchor.current) anchor.current = elem;
  };

  useLayoutEffect(() => {
    observer(styles.elem_hide, styles.elem_show);
    window.scrollTo(0, 0);
  }, []);

  document.onscroll = () => {
    if (window.innerWidth < 1450) {
      window.pageYOffset > 25 ? setBackButton(true) : setBackButton(false);
    }
  };

  let langPack = EN;
  if (language === 'RU') langPack = RU;

  return (
    <>
      <Header language={language} changeLanguage={changeLanguage} />

      <CSSTransition
        in={backButton}
        classNames={{
          enterActive: styles.backButtonEnterActive,
          enterDone: styles.backButtonEnterDone,
        }}
        timeout={300}
      >
        <div
          id="backButton"
          className={styles.backButton}
          onClick={() => navigate(-1)}
        >
          <img alt="back" src={ArrowBlue}></img>
          <p>back</p>
        </div>
      </CSSTransition>

      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <section className={`${styles.intro} ${styles.elem_hide}`}>
              <div className={styles.intro__grid}>
                <div className={styles.intro__title}>
                  <h1>{langPack.intro.h1}</h1>
                  <h2>{langPack.intro.h2}</h2>
                </div>
                <div className={styles.intro__info}>
                  <ul>
                    <li>узнай секреты визуального восприятия</li>
                    <li>поменяй интуицию на систему</li>
                    <li>
                      научись делать{' '}
                      <span style={{ fontWeight: 'bold' }}>дорогую</span>{' '}
                      картинку
                    </li>
                  </ul>
                  <div className={styles.intro__bottom}>
                    <div className={styles.intro__duration}>
                      {langPack.intro.duration.map((item, index) => (
                        <span key={index}>{item}</span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={styles.intro__cover}>
                  <img alt="before/after" src={langPack.intro.img}></img>
                </div>
              </div>
              <GradientButton
                color={
                  'linear-gradient(0deg, rgba(80,9,66,1) 16%, rgba(110,11,88,1) 43%, rgba(202,10,148,1) 100%)'
                }
                text={language === 'RU' ? 'Записаться' : 'Get'}
                onHandleClick={() =>
                  anchor.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              />
            </section>

            <section className={`${styles.title} ${styles.elem_hide}`}>
              <h1>Автор системы:</h1>
            </section>

            <section className={`${styles.elem_hide} ${styles.author}`}>
              <div className={styles.author__container}>
                <div className={`${styles.author__image}`}>
                  <img
                    src={window.innerWidth >= 900 ? AuthorRect : Author}
                    alt="Author Image"
                  />
                </div>

                <div className={`${styles.author__title}`}>
                  <h1>Siamion Azhypa</h1>
                  <div className={styles.author__subtitle}>
                    <h2>VIDEO & ART DIREСTOR, SUPERVISOR.</h2>
                    <a
                      href="https://www.instagram.com/azhypa.mediacenter/"
                      target="_blank"
                      style={{ textDecoration: 'none' }}
                    >
                      <div className={styles.author__insta}>
                        <img src={Insta}></img>
                        <p>@azhypa.mediacenter</p>
                      </div>
                    </a>
                  </div>
                </div>

                <div className={styles.author__body}>
                  <ul>
                    <li>
                      Учился, работал и преподавал в креативном агентстве
                      WARGAMING. c 18-ти лет.
                    </li>
                    <li>
                      Срежиссировал, смонтировал и супервайзил более 100
                      коммерческих видео.
                    </li>
                    <li>
                      В{' '}
                      <Link
                        to="https://azhypa.com/mediacenter/main/portfolio"
                        target="_blank"
                        style={{ color: '#fff' }}
                      >
                        портфолио
                      </Link>{' '}
                      кейсы с Arnold Scharcnagger, Chuck Norris, Sabaton, The
                      Offspring, и т.д.
                    </li>
                  </ul>
                </div>
                <div className={styles.author__footer}>
                  <Link
                    to={`/mediacenter/1/learnFromMe/showReel`}
                    target="_blank"
                    className={styles.author__video}
                  >
                    <img src={Subtract}></img>
                  </Link>

                  <div>
                    <p>
                      «ЧТОБЫ ХУДОЖНИКУ СТОИТЬ ДОРОГО, НУЖНО УМЕТЬ СТРОИТЬ
                      ДОРОГУЮ КАРТИНКУ»
                    </p>
                    <p>Альбебро Мьелго, art director</p>
                  </div>
                </div>
              </div>
            </section>

            <div className={`${styles.video} ${styles.elem_hide}`}>
              <iframe
                allowFullScreen
                title="YouTube video player"
                src={langPack.video}
              ></iframe>
            </div>

            <section className={`${styles.title} ${styles.elem_hide}`}>
              <h1>{langPack.title}</h1>
            </section>

            <div className={styles.possibilities}>
              {langPack.possibilities.map((item, index) => (
                <div key={index} className={styles.elem_hide}>
                  <h1>{item.h}</h1>
                  <ul>
                    {item.ul.map((li, index) => (
                      <li key={index}>{li}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>

            <section className={`${styles.activities} ${styles.elem_hide}`}>
              <h1>{langPack.activities.h1}</h1>
              <div className={styles.activities__grid}>
                {langPack.activities.items.map((item, itemIndex) => (
                  <div key={itemIndex} className={styles.activities__col}>
                    <img
                      style={{ filter: `drop-shadow(${item.shadowColor})` }}
                      src={item.image}
                      alt="activities"
                    ></img>
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
              <div className={styles.activities__description}>
                <p>
                  <span>
                    Знания из этого мастеркласса позволят тебе делать лучше, чем
                  </span>
                  <span style={{ color: '#0BFFAC' }}> 90% </span>
                  <span>твоих конкурентов, а в отдельных нишах — чем</span>
                  <span style={{ color: '#0BFFAC' }}> 99.9%.</span>
                </p>
                <p>{langPack.activities.p}</p>
              </div>
            </section>

            <section className={`${styles.result} ${styles.elem_hide}`}>
              <h1>{langPack.result.h1}</h1>
              <div
                id="resultBody"
                className={styles.result__body}
                onMouseMove={(e) => onResultBody(e)}
              >
                <div id="resultBefore" className={styles.result__before}>
                  <img
                    alt="before"
                    className={styles.result__imageBefore}
                    src={langPack.result.imgBefore}
                  ></img>
                </div>
                <div id="resultAfter" className={styles.result__after}>
                  <img
                    alt="after"
                    className={styles.result__imageAfter}
                    src={langPack.result.imgAfter}
                  ></img>
                </div>
                <div id="resultDragger" className={styles.result__dragger}>
                  <img alt="dragger" src={ArrowLeft}></img>
                  <img alt="right" src={ArrowRight}></img>
                </div>
                <p>{langPack.result.lables[0]}</p>
                <p>{langPack.result.lables[1]}</p>
              </div>
            </section>

            <CSSTransition
              in={planVissibility}
              classNames={{
                enterActive: styles.planBlurActive,
                enterDone: styles.planBlurDone,
              }}
              timeout={0}
            >
              <section
                id="plan"
                className={`${styles.plan} ${styles.elem_hide}`}
              >
                <div
                  onClick={() => setPlanVissibility(!planVissibility)}
                  className={styles.plan__header}
                >
                  <CSSTransition
                    in={planVissibility}
                    classNames={{
                      enterActive: styles.planArrowDone,
                      enterDone: styles.planArrowDone,
                    }}
                    timeout={0}
                  >
                    <img src={Arrow} alt="show"></img>
                  </CSSTransition>
                  <h1>{langPack.plan.h1}</h1>
                </div>
                <CSSTransition
                  in={planVissibility}
                  classNames={{
                    enterActive: styles.planDone,
                    enterDone: styles.planDone,
                  }}
                  timeout={0}
                >
                  <div className={styles.plan__body}>
                    {langPack.plan.columns.map((item, index) => (
                      <div key={index}>
                        <h2>{item.h2}</h2>
                        <ul>
                          {item.ul.map((li, index) => (
                            <li key={index}>{li}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </CSSTransition>
              </section>
            </CSSTransition>

            {langPack.prices.map((item, index) => {
              return (
                <div key={index} className={styles.elem_hide} ref={setAnchor}>
                  <SaleArticle
                    language={language}
                    title={item.title}
                    subtitle={item.subtitle}
                    price={item.price}
                    info={item.info}
                    access={item.access}
                    duration={item.duration}
                    path={item.path}
                    colors={item.palette}
                    marker={item.marker}
                    sale={item.sale}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
