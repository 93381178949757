import { useParams, useNavigate } from 'react-router-dom';

export default function useSlider(){
    const navigate = useNavigate();
    const { slide } = useParams();
    return(
        (callback) => {
            if (slide === 'main') callback([true, false, false]);
            else if(slide === 'about') callback([false, true, false]);
            else if(slide === 'contact') callback([false, false, true]);
            else navigate(`/mediacenter/main/portfolio`);
        }
    );
}