import styles from "./ContentContainer.module.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useContent } from "../../hooks/exports";
import { CSSTransition } from "react-transition-group";
import { Course, Slot, SlotPlayer } from "..";
import { slots } from "../../content/slots/storage";
import { RU, EN } from "../../content/courses/storage";
import { getPlayerRow } from "../../helpers/exports";

export default function ContentContainer({ language }) {
  const [player, setPlayer] = useState({ name: "", video: "", videoDescription: "", row: "" });
  const [isPlay, setIsPlay] = useState(false);
  const [contentController, setContentController] = useState([false, false]);
  const { content } = useParams();
  const changeContent = useContent();

  useEffect(() => {
    changeContent(setContentController);
  }, [content]);

  const onSlotClick = (index, name, video, videoDescription, isActive) => {
    if (isActive) {
      setIsPlay(true);
      setPlayer({ name: name, video: video, videoDescription: videoDescription, row: getPlayerRow(index) });
    } else {
      setIsPlay(false);
      setPlayer(0);
    }
  };

  let slotPlayer;
  if (isPlay)
    slotPlayer = (
      <SlotPlayer name={player.name} video={player.video} videoDescription={player.videoDescription} row={player.row} />
    );
  let courses = EN;
  if (language === "RU") courses = RU;

  return (
    <div className={styles.root}>
      <CSSTransition
        in={contentController[1]}
        classNames={{ enterActive: styles.sliderDone, enterDone: styles.sliderDone }}
        timeout={0}
      >
        <div className={styles.slider}>
          <CSSTransition
            in={contentController[1]}
            classNames={{ enterActive: styles.containerEnterActive, enterDone: styles.containerEnterDone }}
            timeout={0}
          >
            <div className={styles.slotsContainer}>
              {slots.map((slot, index) => (
                <Slot
                  key={index}
                  index={index}
                  id={slot.id}
                  name={slot.name}
                  img={slot.img}
                  videoPrev={slot.videoPrev}
                  video={slot.video}
                  videoDescription={slot.videoDescription}
                  handleClick={onSlotClick}
                  player={player.name}
                ></Slot>
              ))}
              {slotPlayer}
            </div>
          </CSSTransition>
          <CSSTransition
            in={contentController[0]}
            classNames={{ enterActive: styles.containerEnterActive, enterDone: styles.containerEnterDone }}
            timeout={0}
          >
            <div className={styles.coursesWrapper}>
              <div className={styles.coursesContainer}>
                {courses.map((course, index) => (
                  <Course
                    key={index}
                    id={course.id}
                    product={course.product}
                    name={course.name}
                    img={course.img}
                    description={course.description}
                    available={course.available}
                    duration={course.duration}
                    href={course.href}
                    lock={course.lock}
                    language={language}
                  ></Course>
                ))}
              </div>
            </div>
          </CSSTransition>
        </div>
      </CSSTransition>
    </div>
  );
}
