import Slot_01_01 from "./assets/images/Slot_01_01.jpg";
import Slot_01 from "./assets/images/Slot_01.jpg";
import Slot_02 from "./assets/images/Slot_02.jpg";
import Slot_03 from "./assets/images/Slot_03.jpg";
import Slot_04 from "./assets/images/Slot_04.jpg";
import Slot_05 from "./assets/images/DB_Thumb.jpg";
//import Slot_06 from './assets/images/Slot_06.jpg';
import Slot_07 from "./assets/images/Slot_07.jpg";
import Slot_08 from "./assets/images/Slot_08.jpg";
import Slot_09 from "./assets/images/Slot_09.jpg";
import Slot_10 from "./assets/images/Slot_10.jpg";
import Slot_11 from './assets/images/Slot_05.jpg';
import Slot_12 from "./assets/images/Slot_12.jpg";
import Slot_13 from "./assets/images/Slot_13.jpg";
import Slot_14 from "./assets/images/Slot_14.jpg";
import Slot_15 from "./assets/images/Slot_15.jpg";
import Slot_16 from "./assets/images/Slot_16.jpg";
import Slot_17 from "./assets/images/Slot_17.jpg";
import Slot_18 from "./assets/images/Slot_18.jpg";

import SLOT_01_01 from "./assets/videos/SLOT_01_01.mp4";
import SLOT_01 from "./assets/videos/SLOT_01.mp4";
import SLOT_02 from "./assets/videos/SLOT_02.mp4";
import SLOT_03 from "./assets/videos/SLOT_03.mp4";
import SLOT_04 from "./assets/videos/SLOT_04.mp4";
import SLOT_05 from "./assets/videos/DB_preview.mp4";
//import SLOT_06 from './assets/videos/SLOT_06.mp4';
import SLOT_07 from "./assets/videos/SLOT_07.mp4";
import SLOT_08 from "./assets/videos/SLOT_08.mp4";
import SLOT_09 from "./assets/videos/SLOT_09.mp4";
import SLOT_10 from "./assets/videos/SLOT_10.mp4";
import SLOT_11 from './assets/videos/SLOT_05.mp4';
import SLOT_12 from "./assets/videos/SLOT_12.mp4";
import SLOT_13 from "./assets/videos/SLOT_13.mp4";
import SLOT_14 from "./assets/videos/SLOT_14.mp4";
import SLOT_15 from "./assets/videos/SLOT_15.mp4";
import SLOT_16 from "./assets/videos/SLOT_16.mp4";
import SLOT_17 from "./assets/videos/SLOT_17.mp4";
import SLOT_18 from "./assets/videos/SLOT_18.mp4";

export const slots = [
  {
    id: "SLOT_01",
    name: "BIPOLAR",
    img: Slot_01_01,
    videoPrev: SLOT_01_01,
    video: "https://www.youtube.com/embed/-4d-7QP87QA",
    videoDescription: ["AZHYPA MEDIA CENTER", "Directing, producing, CG.", "Unreal Engine 5", "Adobe CC."],
  },
  {
    id: "SLOT_02",
    name: "Chuck Norris in WOT Holiday Ops 2021",
    img: Slot_02,
    videoPrev: SLOT_02,
    video: "https://player.vimeo.com/video/492028662?h=b326d0ddc9",
    videoDescription: [
      "Wargaming",
      "Gameplay scene (0:19-0:26) realtime-CG production, editing.",
      "World of Tanks CORE Realtime Engine.",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_03",
    name: "Sabaton - Steel Commanders (Music Video)",
    img: Slot_01,
    videoPrev: SLOT_01,
    video: "https://www.youtube.com/embed/peTCePu1jMs",
    videoDescription: [
      "Wargaming",
      "Scene in burning city (3:17 - 4:25) realtime-CG production, editing.",
      "World of Tanks CORE Realtime Engine.",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_04",
    name: "Battlepass - Season 5 Intro",
    img: Slot_04,
    videoPrev: SLOT_04,
    video: "https://www.youtube.com/embed/ukT84unmqsw",
    videoDescription: [
      "The Agency of Wargaming.",
      "Directing, Realtime-CG production, editing (0:00 - 0:25).; (9:00 - 12:05)",
      "World of Tanks CORE Realtime Engine.",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_05",
    name: "Deathbound Trailer",
    img: Slot_05,
    videoPrev: SLOT_05,
    video: "https://www.youtube.com/embed/xfsMm0CJYvo?si=g73JKQfbhfxD8W0T",
    videoDescription: [
      "Playsense",
      "Directing, lighting, cinematics.",
      "Unreal Engine 4.27",
      "Adobe CC;",
    ],
  },
  {
    id: "SLOT_06",
    name: "The Offspring in World of Tanks",
    img: Slot_03,
    videoPrev: SLOT_03,
    video: "https://www.youtube.com/embed/3x1epgWNFik",
    videoDescription: [
      "The Agency of Wargaming.",
      "Realtime-CG production, editing.",
      "World of Tanks CORE Realtime Engine.",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_7",
    name: "World of Tanks New Year's Story 2021",
    img: Slot_07,
    videoPrev: SLOT_07,
    video: "https://www.youtube.com/embed/iTfsyxdxmcs",
    videoDescription: [
      "The Agency of Wargaming.",
      "Directing, realtime-CG production, editing.",
      "World of Tanks CORE Realtime Engine.",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_08",
    name: "Arnold Schwarzenegger in World of Tanks Preroll",
    img: Slot_08,
    videoPrev: SLOT_08,
    video:
      "https://www.facebook.com/plugins/video.php?height=317&href=https%3A%2F%2Fwww.facebook.com%2Farnold%2Fvideos%2F1543198539397542%2F&show_text=false&width=560&t=0",
    videoDescription: [
      "The Agency of Wargaming.",
      "Realtime-CG supervising, realtime-CG scenes art-directing.",
      "World of Tanks CORE Realtime Engine.",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_09",
    name: "Battlepass - Season 3 Intro",
    img: Slot_09,
    videoPrev: SLOT_09,
    video: "https://www.youtube.com/embed/VWiI7btRJCk",
    videoDescription: [
      "The Agency of Wargaming.",
      "Directing, realtime-CG production, editing (0:53 - 2:47).",
      "World of Tanks CORE Realtime Engine.",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_10",
    name: "Twitch Prime - Care Package June",
    img: Slot_10,
    videoPrev: SLOT_10,
    video: "https://www.youtube.com/embed/EuW4i_4QnTI",
    videoDescription: [
      "The Agency of Wargaming.",
      "Directing, realtime-CG production, editing.",
      "World of Tanks CORE Realtime Engine.",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_11",
    name: "Cinematic Tank Plugin for Unreal Engine",
    img: Slot_05,
    videoPrev: SLOT_05,
    video: "https://www.youtube.com/embed/EuW4i_4QnTI",
    videoDescription: ["AZHYPA MEDIA CENTER", "Unreal Engine 4.27 + 5.0.", "Adobe CC; Blender; Embergen."],
  },
  {
    id: "SLOT_12",
    name: "Twitch Prime - Care Package Hotel",
    img: Slot_12,
    videoPrev: SLOT_12,
    video: "https://www.youtube.com/embed/KnYqChlWn2Y",
    videoDescription: [
      "The Agency of Wargaming.",
      "Directing, realtime-CG production, editing.",
      "World of Tanks CORE Realtime Engine.",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_13",
    name: "Azhypa at New Layer Meetup 01",
    img: Slot_13,
    videoPrev: SLOT_13,
    video: "https://www.youtube.com/embed/7J2C3C3KD0s",
    videoDescription: [
      "The Agency of Wargaming.",
      "Public speaking",
      "World of Tanks CORE Realtime Engine; Unreal Engine",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_14",
    name: "UE4 Showcase - Steel Hunter Intro",
    img: Slot_14,
    videoPrev: SLOT_14,
    video: "https://www.youtube.com/embed/xN7JFWQawok",
    videoDescription: [
      "The Agency of Wargaming.",
      "Full-cycle video production (0:08 - 0:30)",
      "Unreal Engine 4",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_15",
    name: "Azhypa at New Layer Meetup 02",
    img: Slot_15,
    videoPrev: SLOT_15,
    video: "https://www.youtube.com/embed/JkKO5qUDGGE",
    videoDescription: [
      "The Agency of Wargaming.",
      "Public speaking",
      "World of Tanks CORE Realtime Engine",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_16",
    name: "RNG #117",
    img: Slot_16,
    videoPrev: SLOT_16,
    video: "https://www.youtube.com/embed/6raypeK7L7k",
    videoDescription: [
      "The Agency of Wargaming.",
      "Directing, editing.",
      "World of Tanks CORE Realtime Engine.",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_17",
    name: "Invisible cuts in RNG compilation",
    img: Slot_17,
    videoPrev: SLOT_17,
    video: "https://www.youtube.com/embed/-yp5z77rkBc",
    videoDescription: [
      "AZHYPA MEDIA CENTER",
      "Directing, editing.",
      "World of Tanks CORE Realtime Engine.",
      "Adobe CC.",
    ],
  },
  {
    id: "SLOT_18",
    name: "RNG #115",
    img: Slot_18,
    videoPrev: SLOT_18,
    video: "https://www.youtube.com/embed/4fzADxb_Q3g",
    videoDescription: [
      "The Agency of Wargaming.",
      "Directing, editing.",
      "World of Tanks CORE Realtime Engine.",
      "Adobe CC.",
    ],
  },
];
