import Cover_RU from './assets/images/Cover_RU.jpg';
import Cover_EN from './assets/images/Cover_EN.jpg';

import Activities_1 from './assets/images/Activities_1.png';
import Activities_2 from './assets/images/Activities_2.png';
import Activities_3 from './assets/images/Activities_3.png';

import ResBefore from './assets/images/before.jpg';
import ResAfter from './assets/images/after.jpg';

import Marker_1 from './assets/images/Marker_1.png';
import Marker_2 from './assets/images/Marker_2.png';
import Marker_3 from './assets/images/Marker_2.png';

import Star_1 from './assets/images/Star_1.png';
import Star_2 from './assets/images/Star_2.png';
import Star_3 from './assets/images/Star_3.png';

export const RU = {
  intro: {
    h1: 'ИММЕРСИВНЫЙ ВИЗУАЛ',
    h2: 'МАСТЕР-КЛАСС ДЛЯ КОНТЕНТ-КРЕАТОРОВ',
    img: Cover_RU,
    ul: [
      'узнай секреты визуального восприятия',
      'поменяй интуицию на систему',
      `научись делать дорогую картинку`,
    ],
    duration: ['за', '58', 'мин', 'твоего времени'],
  },
  video: 'https://www.youtube.com/embed/4m0slWtIRC0',
  title: 'КОМУ ПОДОЙДЁТ:',
  possibilities: [
    {
      h: 'НОВИЧКУ:',
      ul: [
        'Поймёшь основы зрительского восприятия',
        'Научишься профессионально анализировать контент',
        'Поймешь, что стоит за "красотой" картинки',
        'Научишься выстраивать эту "красоту" шаг за шагом',
      ],
    },
    {
      h: 'ОПЫТНОМУ:',
      ul: [
        'Усилишь арт-дирекшен своих работ',
        'Научишься отслеживать проблемы в своих работах',
        'Научишься конструктивно отстаивать свои решения перед заказчиком',
      ],
    },
  ],
  activities: {
    h1: 'ТЫ ДЕЛАЕШЬ ИЛИ ХОЧЕШЬ ДЕЛАТЬ:',
    items: [
      {
        image: Activities_1,
        text: 'контент в соцсети',
        shadowColor: '0px 0px 50px #3AFFEF',
      },
      {
        image: Activities_2,
        text: 'фото, видео, арты',
        shadowColor: '0px 0px 50px #CA660A',
      },
      {
        image: Activities_3,
        text: 'фриланс или производство графики',
        shadowColor: '0px 0px 50px #CA0AA5',
      },
    ],
    p: 'Или просто пилить контент круче всех.',
  },
  result: {
    h1: 'ТВОЙ ВИЗУАЛ:',
    lables: ['до', 'после'],
    imgBefore: ResBefore,
    imgAfter: ResAfter,
  },
  plan: {
    h1: 'ПЛАН МАСТЕРКЛАССА',
    columns: [
      {
        h2: '01. Ретроспектива',
        ul: [
          'История визуального искусства.',
          'Технологии изображения визуала.',
          'Анатомия восприятия визуала.',
          'Секреты визуального восприятия.',
        ],
      },
      {
        h2: '02. Формула',
        ul: ['Преобразуем секреты в систему.', 'Составляем формулу.'],
      },
      {
        h2: '03. Практика',
        ul: [
          'Тестируем формулу в вакууме, используя Unreal Engine.',
          'Разгоняемся. Экспериментируем, творчески применяем техники.',
          'Выходим из вакуума. Как применять формулу в быту и на реальных проектах повсеместно.',
        ],
      },
    ],
  },
  prices: [
    {
      title: 'ИММЕРСИВНЫЙ ВИЗУАЛ',
      subtitle: 'Базовая версия',
      price: '$39',
      info: [
        {
          text: 'Материал мастеркласса и формулы.',
          isIncluded: true,
        },
        {
          text: 'Доступ в закрытый чат контент-креаторов.',
          isIncluded: true,
        },
        {
          text: 'Разборы арт-дирекшена ААА проектов на Unreal Engine.',
          isIncluded: false,
        },
        {
          text: 'Персональная стратегическая сессия разбор 1 на 1.',
          isIncluded: false,
        },
      ],
      access: 'Навсегда',
      duration: [58, 'MIN'],
      path: 'https://t.me/ImmersiveVisuals_bot?start=level_basic',
      palette: [
        'rgba(48,48,48,.3)',
        { color: 'rgba(255, 255, 255)' },
        'linear-gradient(0deg, rgba(0,120,48,1) 12%, rgba(0,141,73,1) 36%, rgba(0,161,96,1) 60%, rgba(0,189,130,1) 79%, rgba(0,213,159,1) 100%, rgba(0,255,209,1) 100%)',
      ],
      marker: Marker_1,
      sale: {
        price: '$45',
        image: Star_1,
        color: '#000000',
        discount: '-12%',
      },
    },
    {
      title: 'ИММЕРСИВНЫЙ ВИЗУАЛ (ADV)',
      subtitle: 'Расширенная версия для специалистов',
      price: '$69',
      info: [
        {
          text: 'Материал мастеркласса и формулы.',
          isIncluded: true,
        },
        {
          text: 'Доступ в закрытый чат контент-креаторов.',
          isIncluded: true,
        },
        {
          text: 'Разборы арт-дирекшена ААА проектов на Unreal Engine.',
          isIncluded: true,
        },
        {
          text: 'Персональная стратегическая сессия разбор 1 на 1.',
          isIncluded: false,
        },
      ],
      access: 'Навсегда',
      duration: [73, 'MIN'],
      path: 'https://t.me/ImmersiveVisuals_bot?start=level_advanced',
      palette: [
        'linear-gradient(0deg, rgba(48,48,48,0.23021708683473385) 0%, rgba(247,104,27,0.22461484593837533) 47%, rgba(247,104,27,0.34226190476190477) 63%, rgba(247,104,27,0.2358193277310925) 76%, rgba(48,48,48,0.18539915966386555) 100%)',
        {
          background: 'linear-gradient(to right, #FF5D3A 35%, #FFF500 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
        'linear-gradient(0deg, rgba(77,40,5,1) 0%, rgba(247,80,27,1) 100%)',
      ],
      marker: Marker_2,
      sale: {
        price: '$90',
        image: Star_2,
        discount: '-23%',
      },
    },
    {
      title: 'ИММЕРСИВНЫЙ ВИЗУАЛ (PRO)',
      subtitle: 'Расширенная версия c максимальным результатом',
      price: '$89',
      info: [
        {
          text: 'Материал мастеркласса и формулы.',
          isIncluded: true,
        },
        {
          text: 'Доступ в закрытый чат контент-креаторов.',
          isIncluded: true,
        },
        {
          text: 'Разборы арт-дирекшена ААА проектов на Unreal Engine.',
          isIncluded: true,
        },
        {
          text: 'Персональная стратегическая сессия разбор 1 на 1.',
          isIncluded: true,
        },
      ],
      access: 'Навсегда',
      duration: [118, 'MIN'],
      path: 'https://t.me/ImmersiveVisuals_bot?start=level_pro',
      palette: [
        'linear-gradient(0deg, rgba(48,48,48,0.23021708683473385) 0%, rgba(250,0,206,0.14338235294117652) 47%, rgba(250,0,206,0.2806372549019608) 63%, rgba(250,0,206,0.14058123249299714) 76%, rgba(48,48,48,0.18539915966386555) 100%)',
        {
          background: 'linear-gradient(to right, #3AFFEF 35%, #00FE9D 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
        'linear-gradient(0deg, rgba(80,9,66,1) 16%, rgba(110,11,88,1) 43%, rgba(202,10,148,1) 100%)',
      ],
      marker: Marker_3,
      sale: {
        price: '$150',
        image: Star_3,
        discount: '-40%',
      },
    },
  ],
};

export const EN = {
  intro: {
    h1: 'IMMERSIVE VISUALS',
    h2: 'MASTER-CLASS FOR CONTENT CREATORS.',
    img: Cover_EN,
    ul: [
      'put your picture on the next level',
      'forget about intuition, get knowledge',
      'find out the formula that works',
      'become a visual engineer',
    ],
    duration: ['10', 'yrs', 'of exp in', '45', 'min', 'for this topic.'],
  },
  video: 'https://www.youtube.com/embed/_FFTa2rNDa0',
  title: 'USEFUL FOR:',
  possibilities: [
    {
      h: 'BEGINNER:',
      ul: [
        'You will understand how to do beautifully in any conditions;',
        'Learn how to professionally analyze content;',
        'Get the formula for building a cool picture step by step;',
      ],
    },
    {
      h: 'EXPERIENCED:',
      ul: [
        'You will boost the art direction of your works, therefore you will be able to raise the check;',
        'You will learn to track problems in your work;',
        'You will learn how to constructively defend your decisions to the customer;',
        'You will learn insiders of using Unreal Engine in production;',
      ],
    },
  ],
  activities: {
    h1: 'YOU WORK WITH:',
    items: [
      {
        image: Activities_1,
        text: 'social media content',
        shadowColor: '0px 0px 50px #3AFFEF',
      },
      {
        image: Activities_2,
        text: 'photo, video, art',
        shadowColor: '0px 0px 50px #CA660A',
      },
      {
        image: Activities_3,
        text: 'freelance or graphics production',
        shadowColor: '0px 0px 50px #CA0AA5',
      },
    ],
    p: 'Or just sawing content I twist everyone.',
  },
  result: {
    h1: 'YOUR RESULTS:',
    lables: ['before', 'after'],
    imgBefore: ResBefore,
    imgAfter: ResAfter,
  },
  plan: {
    h1: 'MASTERCLASS PLAN',
    columns: [
      {
        h2: '01. Retrospective',
        ul: [
          'History of visual art.',
          'Technologies of art.',
          'Anatomy of visual perception.',
          'The main goal of an artist.',
        ],
      },
      {
        h2: '02. Techniques research',
        ul: [
          'Analyzing references considering the goal.',
          'Tracing specific techniques and analyzing them.',
          'We make a formula.',
        ],
      },
      {
        h2: '03. Practice',
        ul: [
          'Testing techniques in vacuum using Unreal Engine.',
          'Experimenting, combining, artistically applying our techniques.',
          'Going beyond vacuum. Applying techniques in real life.',
        ],
      },
    ],
  },
  prices: [
    {
      title: 'ADVANCED VISUAL',
      subtitle: 'Basic version',
      price: '$39',
      info: [
        'Access to materials.',
        'Access to private chat with content creators.',
      ],
      access: 'Forever',
      duration: [45, 'MIN'],
      path: 'https://www.figma.com/file/OHMvqyrKSNGLlBHDgJl5OZ/Figma-Basics?node-id=0%3A286&t=D6NDfA4CgtRYr1U8-0',
      //palette: ['#303030 32%'],
      palette: [
        'rgba(48,48,48,.3)',
        { color: 'rgba(255, 255, 255)' },
        'linear-gradient(0deg, rgba(0,120,48,1) 12%, rgba(0,141,73,1) 36%, rgba(0,161,96,1) 60%, rgba(0,189,130,1) 79%, rgba(0,213,159,1) 100%, rgba(0,255,209,1) 100%)',
      ],
      marker: Marker_1,
      sale: {
        price: '$45',
        image: Star_1,
        color: '#000000',
        discount: '-12%',
      },
    },
    {
      title: 'ADVANCED VISUAL (ADV)',
      subtitle: 'Extended version for specialists',
      price: '$69',
      info: [
        'Accesses from the base package, but',
        'More specialized and capacious material.',
        'Analysis of the secrets of expensive Unreal Engine projects.',
      ],
      access: 'Forever',
      duration: [60, 'MIN'],
      path: 'https://www.figma.com/file/OHMvqyrKSNGLlBHDgJl5OZ/Figma-Basics?node-id=0%3A286&t=D6NDfA4CgtRYr1U8-0',
      //palette: ['#303030 32%'],
      palette: [
        'linear-gradient(0deg, rgba(48,48,48,0.23021708683473385) 0%, rgba(247,104,27,0.22461484593837533) 47%, rgba(247,104,27,0.34226190476190477) 63%, rgba(247,104,27,0.2358193277310925) 76%, rgba(48,48,48,0.18539915966386555) 100%)',
        {
          background: 'linear-gradient(to right, #FF5D3A 35%, #FFF500 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
        'linear-gradient(0deg, rgba(77,40,5,1) 0%, rgba(247,80,27,1) 100%)',
      ],
      marker: Marker_2,
      sale: {
        price: '$45',
        image: Star_1,
        color: '#000000',
        discount: '-12%',
      },
    },
    {
      title: 'ADVANCED VISUAL (PRO)',
      subtitle: 'Extended version with maximum result',
      price: '$89',
      info: [
        'Everything from the extended package for specialists.',
        '1-on-1 personal strategy session.',
      ],
      access: 'Forever',
      duration: [89, 'MIN'],
      path: 'https://www.figma.com/file/OHMvqyrKSNGLlBHDgJl5OZ/Figma-Basics?node-id=0%3A286&t=D6NDfA4CgtRYr1U8-0',
      //palette: ['#303030 32%'],
      palette: [
        'linear-gradient(0deg, rgba(48,48,48,0.23021708683473385) 0%, rgba(250,0,206,0.14338235294117652) 47%, rgba(250,0,206,0.2806372549019608) 63%, rgba(250,0,206,0.14058123249299714) 76%, rgba(48,48,48,0.18539915966386555) 100%)',
        {
          background: 'linear-gradient(to right, #3AFFEF 35%, #00FE9D 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
        'linear-gradient(0deg, rgba(80,9,66,1) 16%, rgba(110,11,88,1) 43%, rgba(202,10,148,1) 100%)',
      ],
      marker: Marker_3,
      sale: {
        price: '$45',
        image: Star_1,
        color: '#000000',
        discount: '-12%',
      },
    },
  ],
};
