export default function getPlayerRow(index) {
  const screenWidth = window.innerWidth;
  let rowNum;

  if (screenWidth < 520) {
    rowNum = index + 2;
  } else if (screenWidth > 1332) {
    rowNum = Math.floor(index / 3) + 2;
  } else {
    rowNum = Math.floor(index / 2) + 2;
  }

  return rowNum;
}
