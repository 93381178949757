import { useState, useEffect } from "react";
import "./App.css";
import { Home, MediaCenter, ImmersiveVisuals } from "./pages/exports";
import { Routes, Route } from "react-router-dom";

async function detectRegion() {
  const response = await fetch("https://api.db-ip.com/v2/free/self");
  const data = await response.json();
  if (["BY", "UA", "RU", "KZ", "KG", "AB"].includes(data.countryCode))
    return "RU";
  return "EN";
}

export default function App() {
  const [language, setLanguage] = useState("RU");

  // useEffect(() => {
  //   if (localStorage.getItem("language") !== null) {
  //     setLanguage(JSON.parse(localStorage.language));
  //   } else detectRegion().then(setLanguage);
  // }, []);

  useEffect(() => {
    localStorage.language = JSON.stringify(language);
  }, [language]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />

        <Route
          path="/mediacenter/:slide/:content"
          element={
            <MediaCenter
              language={language}
              changeLanguage={(lang) => setLanguage(lang)}
            />
          }
        />

        <Route
          path="/mediacenter/:slide/:content/:showReel"
          element={
            <MediaCenter
              language={language}
              changeLanguage={(lang) => setLanguage(lang)}
            />
          }
        />

        <Route
          path="/mediacenter/ImmersiveVisuals"
          element={
            <ImmersiveVisuals
              language={language}
              changeLanguage={(lang) => setLanguage(lang)}
            />
          }
        />

        <Route
          path="*"
          element={
            <MediaCenter
              language={language}
              changeLanguage={(lang) => setLanguage(lang)}
            />
          }
        />
      </Routes>
    </div>
  );
}
