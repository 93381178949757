import Cover_01 from "./assets/images/Cover_01.jpg";
import Cover_02 from "./assets/images/Cover_02.jpg";

export const EN = [
  {
    id: "ImmersiveVisuals",
    product: "MASTER-CLASS FOR CONTENT CREATORS",
    name: "IMMERSIVE VISUALS",
    img: Cover_01,
    description: {
      p: [
        "Take your picture to the next level.",
        "Jump to the next level of conscious visual creation. Learn techniques and tricks that WORK. Create a product that sells itself.",
        "Useful for any kind of visual content.",
      ],
      li: [],
    },
    available: "forever",
    duration: 45,
    lock: true,
  },
  {
    id: "ImmersiveVisuals",
    product: "Master-class for content creators",
    name: "Rules of cinematic",
    img: Cover_02,
    description: {
      p: [
        "Things to know and use consciously to improve the quality of your visuals tremendously.",
        "Useful for any kind of visual content: from Instagram stories and TikTok videos to commercial cinematography, photography and art.",
      ],
      li: [],
    },
    available: "forever",
    duration: 45,
    lock: true,
  },
];

export const RU = [
  {
    id: "ImmersiveVisuals",
    product: "МАСТЕР-КЛАСС ДЛЯ КОНТЕНТ-КРЕАТОРОВ",
    name: "ИММЕРСИВНЫЙ ВИЗУАЛ",
    img: Cover_01,
    description: {
      p: [
        "Выведи свою картинку на некст-левел.",
        "Освой осознанный подход к созданию визуального контента. Научись приёмам и техникам, которые работают. Создавай продукты, продающие сами себя.",
        "Подходит для любого типа визуального контента.",
      ],
      li: [],
    },
    available: "навсегда",
    duration: 45,
    lock: false,
  },
  {
    id: "ImmersiveVisuals",
    product: "Master-class for content creators",
    name: "Rules of cinematic",
    img: Cover_02,
    description: {
      p: [
        "Things to know and use consciously to improve the quality of your visuals tremendously.",
        "Useful for any kind of visual content: from Instagram stories and TikTok videos to commercial cinematography, photography and art.",
      ],
      li: [],
    },
    available: "forever",
    duration: 45,
    lock: true,
  },
];
