export default function observer(className1, className2) {
  function onEntry(entry) {
    entry.forEach(change => {
      if (change.isIntersecting) change.target.classList.add(className2);
    });
  }
  let options = { rootMargin: '0px 0px -0px 0px' };
  let observer = new IntersectionObserver(onEntry, options);
  let elements = document.getElementsByClassName(className1);
  for (let elem of elements) { observer.observe(elem) }
}