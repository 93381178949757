import styles from "./Header.module.css";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { RU, EN } from "./data";

export default function Header({ language, changeLanguage, changePlayer, player }) {
  const [visibility, setVisibility] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [burger, setBurger] = useState(false);
  const [navBtns, setNavBtns] = useState([false, false]);
  const [langState, setLangState] = useState([false, false]);

  const navigate = useNavigate();
  const { slide, content } = useParams();

  useEffect(() => {
    setVisibility(true);
  }, []);

  useEffect(() => {
    setLangState(language === "RU" ? [true, false] : [false, true]);
  }, [language]);

  useEffect(() => {
    onNavBtn(slide);
  }, [slide]);

  window.onscroll = () => {
    window.pageYOffset > 50 ? setScrolled(true) : setScrolled(false);
  };

  const onBurger = () => {
    if (window.innerWidth < 800) {
      if (burger) document.querySelector("body").classList.remove(styles.noScroll);
      else document.querySelector("body").classList.add(styles.noScroll);
    }
    setBurger(!burger);
  };

  const onNavBtn = (slide) => {
    if (slide === "main") {
      setNavBtns([false, false]);
    } else if (slide === "about") {
      setNavBtns([true, false]);
      navigate(`/mediacenter/about/${content}`);
    } else if (slide === "contact") {
      setNavBtns([false, true]);
    }
    try {
      changePlayer(null);
    } catch {}
  };

  let langPack = EN;
  if (language === "RU") langPack = RU;

  return (
    <header className={styles.root}>
      <CSSTransition
        in={player !== null}
        classNames={{
          enterActive: styles.wrapperEnterDoneByIntroPlayer,
          enterDone: styles.wrapperEnterDoneByIntroPlayer,
        }}
        timeout={0}
      >
        <CSSTransition
          in={scrolled}
          classNames={{ enterActive: styles.wrapperEnterDone, enterDone: styles.wrapperEnterDone }}
          timeout={0}
        >
          <div className={styles.wrapper}>
            <div className={styles.logo}>
              <Link to="/">
                <CSSTransition
                  in={visibility}
                  classNames={{ enterActive: styles.azhypaEnterActive, enterDone: styles.azhypaEnterDone }}
                  timeout={0}
                >
                  <span className={styles.azhypa}>AZHYPA</span>
                </CSSTransition>
              </Link>
              <Link
                to={`/mediacenter/main/${content}`}
                onClick={() => {
                  onNavBtn("main");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <CSSTransition
                  in={visibility}
                  classNames={{ enterActive: styles.mediaCenterEnterActive, enterDone: styles.mediaCenterEnterDone }}
                  timeout={0}
                >
                  <span className={styles.mediaCenter}>MEDIA CENTER</span>
                </CSSTransition>
              </Link>
            </div>
            <CSSTransition
              in={burger}
              classNames={{ enterActive: styles.navEnterDone, enterDone: styles.navEnterDone }}
              timeout={0}
            >
              <nav className={styles.nav}>
                <ul>
                  <li className={styles.navItem}>
                    <Link to={`/mediacenter/about/${content}`}>
                      <CSSTransition
                        in={visibility}
                        classNames={{ enterActive: styles.linkEnterActive, enterDone: styles.linkEnterDone }}
                        timeout={0}
                      >
                        <CSSTransition
                          in={navBtns[0]}
                          classNames={{ enterActive: styles.navBtnDone, enterDone: styles.navBtnDone }}
                          timeout={0}
                        >
                          <div
                            className={styles.link}
                            onClick={() => {
                              onNavBtn("about");
                              onBurger();
                              window.scrollTo({ top: 0, behavior: "smooth" });
                            }}
                          >
                            {langPack.navigation[0]}
                          </div>
                        </CSSTransition>
                      </CSSTransition>
                    </Link>
                  </li>
                  <li className={styles.navItem}>
                    <Link to={`/mediacenter/contact/${content}`}>
                      <CSSTransition
                        in={visibility}
                        classNames={{ enterActive: styles.linkEnterActive, enterDone: styles.linkEnterDone }}
                        timeout={0}
                      >
                        <CSSTransition
                          in={navBtns[1]}
                          classNames={{ enterActive: styles.navBtnDone, enterDone: styles.navBtnDone }}
                          timeout={0}
                        >
                          <div
                            className={styles.link}
                            onClick={() => {
                              onNavBtn("contact");
                              onBurger();
                              window.scrollTo({ top: 0, behavior: "smooth" });
                            }}
                          >
                            {langPack.navigation[1]}
                          </div>
                        </CSSTransition>
                      </CSSTransition>
                    </Link>
                  </li>
                  {/* <li className={styles.navItem}>
                    <CSSTransition
                      in={visibility}
                      classNames={{ enterActive: styles.linkEnterActive, enterDone: styles.linkEnterDone }}
                      timeout={0}
                    >
                      <div
                        className={styles.language}
                        onClick={() => {
                          changeLanguage(langState[0] ? "EN" : "RU");
                          onBurger();
                        }}
                      >
                        <CSSTransition
                          in={langState[0]}
                          classNames={{ enterActive: styles.navBtnLanguageDone, enterDone: styles.navBtnLanguageDone }}
                          timeout={0}
                        >
                          <span>RU</span>
                        </CSSTransition>
                        <span>/</span>
                        <CSSTransition
                          in={langState[1]}
                          classNames={{ enterActive: styles.navBtnLanguageDone, enterDone: styles.navBtnLanguageDone }}
                          timeout={0}
                        >
                          <span>EN</span>
                        </CSSTransition>
                      </div>
                    </CSSTransition>
                  </li> */}
                </ul>
              </nav>
            </CSSTransition>
            <CSSTransition
              in={visibility}
              classNames={{ enterActive: styles.burgerShowEnterDone, enterDone: styles.burgerShowEnterDone }}
              timeout={0}
            >
              <CSSTransition
                in={burger}
                classNames={{ enterActive: styles.burgerEnterDone, enterDone: styles.burgerEnterDone }}
                timeout={0}
              >
                <div onClick={onBurger} className={styles.burger}>
                  <CSSTransition
                    in={burger}
                    classNames={{ enterActive: styles.burgerIconEnterDone, enterDone: styles.burgerIconEnterDone }}
                    timeout={0}
                  >
                    <span className={styles.burgerIcon}></span>
                  </CSSTransition>
                </div>
              </CSSTransition>
            </CSSTransition>
          </div>
        </CSSTransition>
      </CSSTransition>
      <CSSTransition
        in={scrolled}
        classNames={{ enterActive: styles.gredientEnterDone, enterDone: styles.gredientEnterDone }}
        timeout={0}
      >
        <div className={styles.gredient}></div>
      </CSSTransition>
    </header>
  );
}
