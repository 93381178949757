import linkedin from "./assets/linkedin.svg";
import youtube from "./assets/youtube.svg";
import instagram from "./assets/instagram.svg";
import telegram from "./assets/telegram.svg";
import tiktok from "./assets/tiktok.svg";
import mail from "./assets/mail.svg";

const socials = [
  {
    icon: linkedin,
    link: "https://www.linkedin.com/in/siamion-azhypa-3242171a3/",
  },
  {
    icon: youtube,
    link: "https://www.youtube.com/c/AzhypaMediaCenter/videos",
  },
  {
    icon: instagram,
    link: "https://www.instagram.com/azhypa.mediacenter/",
  },
  {
    icon: telegram,
    link: "https://t.me/azhypa",
  },
  {
    icon: tiktok,
    link: "https://in.tiktok.com/@azhypa",
  },
  {
    icon: mail,
    link: "mailto:s.azhypa@gmail.com",
  },
];

export default socials;
