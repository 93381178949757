import styles from './Intro.module.css';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { RU, EN } from './data';
import * as exports from './exports.js';
import { logo, socials } from '../../shared/exports';
import { addCustomCursor } from '../../helpers/exports';
import { useContent, useSlider } from '../../hooks/exports';
import Author from './assets/Author.png';
import AuthorRect from './assets/RectangleAuthor.png';
import Insta from './assets/InstIcon.svg';
import ShowReel from './assets/ShowReel.png';

const onWatchShowReel = (isHover) => {
  try {
    let cursor = document.querySelector('#cursor img');
    if (isHover) cursor.style.opacity = 1;
    else cursor.style.opacity = 0;
  } catch {}
};

export default function Intro({ language, changePlayer, player }) {
  const [visibility, setVisibility] = useState(false);
  const [slider, setSlider] = useState([false, false, false]);
  const [btnLinks, setBtnLinks] = useState(['', '']);
  const [contentController, setContentController] = useState([false, false]);
  const navigate = useNavigate();
  const { slide, content, showReel } = useParams();
  const changeContent = useContent();
  const changeSlide = useSlider();

  useEffect(() => {
    setVisibility(true);
    addCustomCursor();
    if (showReel === 'showReel') {
      navigate(`/mediacenter/${slide}/${content}/showReel`);
      changePlayer('https://www.youtube.com/embed/VrRjjqkb-r0');
    } else {
      try {
        document.getElementById(showReel).click();
      } catch {
        navigate(`/mediacenter/${slide}/${content}`);
      }
    }
  }, []);

  useEffect(() => {
    if (showReel !== 'showReel') changePlayer(null);
  }, [showReel]);

  useEffect(() => {
    changeContent(setContentController);
  }, [content]);

  useEffect(() => {
    if (contentController[1])
      changePlayer('https://www.youtube.com/embed/VrRjjqkb-r0');
  }, [contentController]);

  useEffect(() => {
    changeSlide(setSlider);
  }, [slide]);

  useEffect(() => {
    if (slider.indexOf(true) === 0)
      setBtnLinks(['', `/mediacenter/about/${content}`]);
    else if (slider.indexOf(true) === 1)
      setBtnLinks([
        `/mediacenter/main/${content}`,
        `/mediacenter/contact/${content}`,
      ]);
    else if (slider.indexOf(true) === 2)
      setBtnLinks([`/mediacenter/about/${content}`, '']);
  }, [slider]);

  let langPack = EN;
  if (language === 'RU') langPack = RU;

  let playerMarkup;
  if (player) {
    playerMarkup = (
      <div className={styles.overlay}>
        <iframe
          src={player}
          title="YouTube video player"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <div
          className={styles.overlayCloseBtn}
          onClick={() => {
            navigate(`/mediacenter/${slide}/${content}`);
            changePlayer(null);
          }}
        ></div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {playerMarkup}

      <CSSTransition
        in={player !== null}
        classNames={{
          enterActive: styles.sliderHide,
          enterDone: styles.sliderHide,
        }}
        timeout={0}
      >
        <div className={styles.fullScreen}>
          <CSSTransition
            in={slider[0]}
            classNames={{
              enterActive: styles.sliderBtnActive,
              enterDone: styles.sliderBtnDone,
            }}
            timeout={0}
          >
            <Link to={btnLinks[0]} className={styles.sliderBtn}>
              <img src={exports.arrowLeft} alt="previous slide"></img>
            </Link>
          </CSSTransition>
          <CSSTransition
            in={slider[2]}
            classNames={{
              enterActive: styles.sliderBtnActive,
              enterDone: styles.sliderBtnDone,
            }}
            timeout={0}
          >
            <Link
              to={btnLinks[1]}
              className={`${styles.sliderBtn} ${styles.sliderBtnRight}`}
            >
              <img src={exports.arrowRight} alt="next slide"></img>
            </Link>
          </CSSTransition>
          <div className={styles.fullScreenVideoWrapper}>
            <CSSTransition
              in={slider[0]}
              classNames={{
                enterActive: styles.fullScreenVideoDone,
                enterDone: styles.fullScreenVideoDone,
              }}
              timeout={0}
            >
              <video
                autoPlay
                playsInline
                muted
                loop
                preload="auto"
                poster={exports.poster}
                className={styles.fullScreenVideo}
              >
                <source type="video/mp4" src={exports.backdrop}></source>
              </video>
            </CSSTransition>
            <CSSTransition
              in={slider[1]}
              classNames={{
                enterActive: styles.fullScreenVideoDone,
                enterDone: styles.fullScreenVideoDone,
              }}
              timeout={0}
            >
              <img
                alt="banner About"
                className={styles.fullScreenVideo}
                src={exports.bannerAbout}
              />
            </CSSTransition>
            <CSSTransition
              in={slider[2]}
              classNames={{
                enterActive: styles.fullScreenVideoDone,
                enterDone: styles.fullScreenVideoDone,
              }}
              timeout={0}
            >
              <img
                alt="banner Socials"
                className={styles.fullScreenVideo}
                src={exports.bannerSocials}
              />
            </CSSTransition>
          </div>
          <CSSTransition
            in={slider[0]}
            classNames={{
              enterActive: styles.toSlideVideoDone,
              enterDone: styles.toSlideVideoDone,
            }}
            timeout={0}
          >
            <CSSTransition
              in={slider[1]}
              classNames={{
                enterActive: styles.toSlideAboutDone,
                enterDone: styles.toSlideAboutDone,
              }}
              timeout={0}
            >
              <CSSTransition
                in={slider[2]}
                classNames={{
                  enterActive: styles.toSlideSocialsDone,
                  enterDone: styles.toSlideSocialsDone,
                }}
                timeout={0}
              >
                <div className={styles.slider}>
                  <div className={styles.slideWrapper}>
                    <CSSTransition
                      in={visibility}
                      classNames={{
                        enterActive: styles.introSlideVideoEnterDone,
                        enterDone: styles.introSlideVideoEnterDone,
                      }}
                      timeout={0}
                    >
                      <div
                        className={`${styles.slide} ${styles.introSlideVideo}`}
                      >
                        <img alt="AZHYPA MEDIA CENTER" src={logo}></img>
                        <hr></hr>
                        <h2>{langPack.slide_1}</h2>
                      </div>
                    </CSSTransition>
                  </div>
                  <div className={styles.slideWrapper}>
                    <div
                      className={`${styles.slide} ${styles.introSlideAbout}`}
                    >
                      {window.innerWidth >= 960 ? (
                        <section
                          className={`${styles.elem_hide} ${styles.author}`}
                        >
                          <div className={styles.author__container}>
                            <div className={`${styles.author__image}`}>
                              <img
                                src={
                                  window.innerWidth >= 900 ? AuthorRect : Author
                                }
                                alt="Author Image"
                              />
                            </div>

                            <div className={`${styles.author__title}`}>
                              <h1>Siamion Azhypa</h1>
                              <div className={styles.author__subtitle}>
                                <h2>VIDEO & ART DIREСTOR, SUPERVISOR.</h2>
                                <a
                                  href="https://www.instagram.com/azhypa.mediacenter/"
                                  style={{ textDecoration: 'none' }}
                                >
                                  <div className={styles.author__insta}>
                                    <img src={Insta}></img>
                                    <p>@azhypa.mediacenter</p>
                                  </div>
                                </a>
                              </div>
                            </div>

                            <div className={styles.author__body}>
                              <ul>
                                <li>
                                  Учился, работал и преподавал в креативном
                                  агентстве WARGAMING. c 18-ти лет.
                                </li>
                                <li>
                                  Срежиссировал, смонтировал и супервайзил более
                                  100 коммерческих видео.
                                </li>
                                <li>
                                  В{' '}
                                  <Link
                                    to="https://azhypa.com/mediacenter/main/portfolio"
                                    style={{ color: '#fff' }}
                                  >
                                    портфолио
                                  </Link>{' '}
                                  кейсы с Arnold Scharcnagger, Chuck Norris,
                                  Sabaton, The Offspring, и т.д.
                                </li>
                              </ul>
                            </div>
                            <div className={styles.author__footer}>
                              <div className={`${styles.author__video}`}>
                                <Link
                                  to={`/mediacenter/${slide}/${content}/showReel`}
                                  onClick={() => {
                                    changePlayer(
                                      'https://www.youtube.com/embed/VrRjjqkb-r0'
                                    );
                                    window.scrollTo({
                                      top: 0,
                                      behavior: 'smooth',
                                    });
                                  }}
                                >
                                  <img alt="ShowReel" src={ShowReel}></img>
                                </Link>
                              </div>
                              <div>
                                <p>
                                  «ЧТОБЫ ХУДОЖНИКУ СТОИТЬ ДОРОГО, НУЖНО УМЕТЬ
                                  СТРОИТЬ ДОРОГУЮ КАРТИНКУ»
                                </p>
                                <p>Альбебро Мьелго, art director</p>
                              </div>
                            </div>
                          </div>
                        </section>
                      ) : (
                        <>
                          <img alt="avatar" src={exports.avatar}></img>
                          <h2>{langPack.slide_2}</h2>
                        </>
                      )}
                    </div>
                  </div>
                  <div className={styles.slideWrapper}>
                    <div
                      className={`${styles.slide} ${styles.introSlideSocials}`}
                    >
                      <ul className={styles.footer_column}>
                        {socials.map((item, index) => (
                          <li key={index} className={styles.grid_item}>
                            <a href={item.link}>
                              <img src={item.icon} alt={item.link}></img>
                            </a>
                          </li>
                        ))}
                      </ul>
                      <h2>{langPack.slide_3}</h2>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            </CSSTransition>
          </CSSTransition>
          <div className={styles.introBtns}>
            <CSSTransition
              in={visibility}
              classNames={{
                enterActive: styles.introBtnWatchShowReelEnterActiveVisibility,
                enterDone: styles.introBtnWatchShowReelEnterDoneVisibility,
              }}
              timeout={0}
            >
              <Link
                to={`/mediacenter/${slide}/${content}/showReel`}
                onClick={() => {
                  changePlayer('https://www.youtube.com/embed/VrRjjqkb-r0');
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                onMouseEnter={() => onWatchShowReel(true)}
                onMouseLeave={() => onWatchShowReel(false)}
                className={styles.introBtnWatchShowReel}
              >
                {langPack.buttons[0]}
              </Link>
            </CSSTransition>
            <CSSTransition
              in={visibility}
              classNames={{
                enterActive: styles.introBtnContactEnterActiveVisibility,
                enterDone: styles.introBtnContactEnterDoneVisibility,
              }}
              timeout={0}
            >
              <CSSTransition
                in={slider[2]}
                classNames={{
                  enterActive: styles.contactBtnEnterDone,
                  enterDone: styles.contactBtnEnterDone,
                }}
                timeout={0}
              >
                <Link
                  to={`/mediacenter/contact/${content}`}
                  className={styles.introBtnContact}
                >
                  {langPack.buttons[1]}
                </Link>
              </CSSTransition>
            </CSSTransition>
          </div>
        </div>
      </CSSTransition>

      <div className={styles.contentController}>
        <CSSTransition
          in={contentController[0]}
          classNames={{
            enterActive: styles.contentControllerPortfolioDone,
            enterDone: styles.contentControllerPortfolioDone,
          }}
          timeout={0}
        >
          <button
            onClick={() => {
              changePlayer(null);
              navigate(`/mediacenter/${slide}/portfolio`);
              window.scrollTo({
                top: window.innerHeight - 138.8,
                behavior: 'smooth',
              });
            }}
            className={styles.contentControllerBtn}
          >
            {langPack.contentController[0]}
          </button>
        </CSSTransition>
        <CSSTransition
          in={contentController[1]}
          classNames={{
            enterActive: styles.contentControllerEducationDone,
            enterDone: styles.contentControllerEducationDone,
          }}
          timeout={0}
        >
          <button
            onClick={() => {
              changePlayer('https://www.youtube.com/embed/VrRjjqkb-r0');
              navigate(`/mediacenter/${slide}/learnFromMe`);
            }}
            className={styles.contentControllerBtn}
          >
            {langPack.contentController[1]}
          </button>
        </CSSTransition>
      </div>
    </div>
  );
}
