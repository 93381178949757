import styles from "./Course.module.css";
import { Link } from "react-router-dom";
import { locker } from "./exports";

export default function Course({ id, product, name, img, description, available, duration, lock, language }) {
  let lables = ["Available", "Duration", "improve your content"];
  if (language === "RU") lables = ["Доступ", "Длительность", "улучши свой контент"];

  let content = (
    <>
      <div className={lock ? styles.blur : null}>
        <img className={styles.cover} src={img} alt="course-cover"></img>
        <div className={styles.container}>
          <h2>{product}</h2>
          <h1>{name}</h1>
          <div className={styles.description}>
            {description.p.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>
          <ul>
            {description.li.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className={lock ? styles.blur : null}>
        <div className={styles.info}>
          <div className={styles.available}>
            <p>{available}</p>
            <p>{lables[0]}</p>
          </div>
          <div className={styles.duration}>
            <p>
              <span>{duration}</span>
              <span>min</span>
            </p>
            <p>{lables[1]}</p>
          </div>
        </div>
        <button className={styles.btn}>{lables[2]}</button>
      </div>
    </>
  );

  if (lock)
    return (
      <div className={styles.root}>
        <div className={styles.locker}>
          <img src={locker} alt="locker"></img>
          <h1>In progress</h1>
        </div>
        <div className={styles.slick}></div>
        {content}
      </div>
    );

  return (
    <Link to={"/mediacenter/" + id} className={`${styles.root} ${styles.unlock}`}>
      {content}
    </Link>
  );
}
