import styles from './Home.module.css';
import stylesMobile from './HomeMobile.module.css';
import { Link } from 'react-router-dom';
import Banner from './assets/Banner.jpg';
import Logo from './assets/Logo.svg';


export default function Home(props) {

    const onLeftSide = (e, flag) => {
        const bg = document.querySelector('#bg');
        const leftSide = document.querySelector('#leftSide');
        const logo = document.querySelector('#leftTitle');

        if (flag) {
            if ((e.relatedTarget !== leftSide) && (e.relatedTarget !== bg)) {
                bg.classList.add(styles.width90);
                logo.classList.add(styles.scale);
            }
        } else {
            if ((e.relatedTarget !== leftSide) && (e.relatedTarget !== bg)) {
                bg.classList.remove(styles.width90);
                logo.classList.remove(styles.scale);
            }
        }
    }

    if (window.screen.width < 1000) return(
        <div className={stylesMobile.root}>
            <a href="" className={styles.logo}>AZHYPA</a>
            <Link to='/mediacenter'>
                <div className={stylesMobile.top}>
                    <img alt='banner' src={Banner}></img>
                    <div className={stylesMobile.title_1}>
                        <img alt='logo' src={Logo}></img>
                    </div>
                </div>
            </Link>
            <div className={stylesMobile.bottom}>
                <div className={stylesMobile.title_2}>
                    <h1>SECRET</h1>
                    <h2>SEGMENT</h2>
                </div>
            </div>
        </div>
    );
    return(
        <div className={styles.root}>
        <a href="" className={styles.logo}>AZHYPA</a>
        <Link to='/mediacenter'>
            <div className={styles.bg}>
                <img alt='background' id='bg' src={Banner}
                    onMouseEnter={(e) => onLeftSide(e, true)}
                    onMouseLeave={(e) => onLeftSide(e, false)}>
                </img>
            </div>
            <div className={styles.leftSide}>
                <div id='leftSide' className={`${styles.title} ${styles.leftTitle}`}
                    onMouseEnter={(e) => onLeftSide(e, true)}
                    onMouseLeave={(e) => onLeftSide(e, false)}>
                    <img alt='logo' id='leftTitle' src={Logo}></img>
                </div>
            </div>
        </Link>
        <div className={styles.rightSide}>
            <div className={`${styles.title} ${styles.rightTitle}`}>
                <h1>SECRET</h1>
                <h2>SEGMENT</h2>
            </div>
        </div>
    </div>
    );
}