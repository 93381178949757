import styles from "./Footer.module.css";
import socials from "../../shared/socials";
import logo from "../../shared/logo";

export default function Footer(props) {
  return (
    <footer className={styles.root}>
      <div className={styles.container}>
        <div className={styles.grid}>
          <ul className={styles.column}>
            {socials.map((item, index) => (
              <li key={index} className={styles.gridItem}>
                <a href={item.link}>
                  <img src={item.icon} alt={item.link}></img>
                </a>
              </li>
            ))}
          </ul>
          <ul className={styles.column}>
            <li className={styles.gridItem}>
              <img
                src={logo}
                alt="AZHYPA MEDIA CENTER"
                className={styles.logo}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              ></img>
            </li>
          </ul>

          <ul className={`${styles.column} ${styles.policy}`}>
            <li className={styles.gridItem}>
              <a href="/documents/Oferta.pdf" target="_blank" rel="noreferrer">
                ПУБЛИЧНАЯ ОФЕРТА
              </a>
            </li>
            <li className={styles.gridItem}>
              <a
                href="/documents/Politics.pdf"
                target="_blank"
                rel="noreferrer"
              >
                ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
              </a>
            </li>
          </ul>

          <ul className={styles.column}>
            <li className={styles.gridItem}>
              <span>Individual Entrepreneur Siamion Azhypa</span>
            </li>
            <li className={styles.gridItem}>
              <span>IN 302269863</span>
            </li>
            <li className={styles.gridItem}>
              <span>azhypa.feedback@gmail.com</span>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
