import styles from "./SlotPlayer.module.css";
import { useEffect } from "react";

export default function SlotPlayer({ name, video, videoDescription, row }) {
  useEffect(() => {
    if (name) {
      window.scrollTo({
        top: document.getElementById("slotPlayer").getBoundingClientRect().top + window.pageYOffset,
        behavior: "smooth",
      });
    }
  }, [name]);

  return (
    <div id="slotPlayer" className={styles.root} style={{ gridRow: row }}>
      <div className={styles.container}>
        <h1> {name} </h1>
        <div className={styles.video}>
          <iframe
            src={video}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <span className={styles.loader}>Loading...</span>
        </div>
        <div className={styles.text}>
          <p>
            <span> Production: </span>
            <span> {videoDescription[0]} </span>
          </p>
          <p>
            <span> Azhypa’s role: </span>
            <span> {videoDescription[1]} </span>
          </p>
          <p>
            <span> Engine: </span>
            <span> {videoDescription[2]} </span>
          </p>
          <p>
            <span> Software: </span>
            <span> {videoDescription[3]} </span>
          </p>
        </div>
      </div>
    </div>
  );
}
