import styles from "./Slot.module.css";
import { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useNavigate, useParams } from "react-router-dom";

export default function Slot({ index, id, name, img, videoPrev, video, videoDescription, handleClick, player }) {
  const navigate = useNavigate();
  const { slide, content, showReel } = useParams();

  const [isActive, setIsActive] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const videoRef = useRef();

  useEffect(() => {
    if (player !== name) setIsActive(false);
  }, [player]);

  useEffect(() => {
    if (showReel === "showReel" && isActive) {
      handleClick(index, name, video, videoDescription, false);
    }
  }, [showReel]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsHover(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <div
      to={id}
      id={id}
      className={styles.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        handleClick(index, name, video, videoDescription, !isActive);
        setIsActive(!isActive);
        if (isActive) navigate(`/mediacenter/${slide}/${content}`);
        else navigate(`/mediacenter/${slide}/${content}/${id}`);
      }}
    >
      <div className={styles.content}>
        <CSSTransition
          in={isHover}
          classNames={{ enterActive: styles.slotImgDone, enterDone: styles.slotImgDone }}
          timeout={0}
        >
          <img src={img} alt={id} />
        </CSSTransition>
        <CSSTransition
          in={isHover}
          classNames={{ enterActive: styles.slotVideoDone, enterDone: styles.slotVideoDone }}
          timeout={0}
        >
          <video ref={videoRef} loop muted preload="none">
            {isHovered && <source src={videoPrev} type="video/mp4" />}
          </video>
        </CSSTransition>
        <CSSTransition
          in={isHover}
          classNames={{ enterActive: styles.slotTextDone, enterDone: styles.slotTextDone }}
          timeout={0}
        >
          <div className={styles.text}>{name}</div>
        </CSSTransition>
      </div>
    </div>
  );
}
