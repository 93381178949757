export const RU = {
    slide_1: 'уникальные видео для уникальных продуктов.',
    slide_2: 'Привет, я Семён Ажипа, или Ажипа Медиа Центр. Я арт директор и режиссер видео, супервайзер, художник реалтайм графики и чето еще.',
    slide_3: 'Свяжись со мной через соцсети, или присоединяйся к аудитории.',
    buttons: ['Смотреть ShowReel', 'Связаться'],
    contentController: ['Портфолио', 'Учись у меня']
}

export const EN = {
    slide_1: 'is where AAA videos are created.',
    slide_2: 'Hey! I’m Siamion Azhypa a.k.a. AZHYPA MEDIA CENTER. I art- and video direct, supervise, create realtime CG and more.',
    slide_3: 'Feel free to reach out & follow me on socials.',
    buttons: ['Watch ShowReel', 'Contact'],
    contentController: ['Portfolio', 'Learn from me']
}