import styles from './SaleArticle.module.css';
import { GradientButton } from '..';
import greyMarker from './greyMarker.png';

export default function SaleArticle({
  language,
  title,
  subtitle,
  price,
  info,
  access,
  duration,
  path,
  colors,
  marker,
  sale,
}) {
  return (
    <article className={styles.root} style={{ background: colors[0] }}>
      <div className={styles.content}>
        <h1 style={{ ...colors[1] }}>{title}</h1>
        <h2>{subtitle}</h2>
        <div className={styles.price}>
          {sale ? (
            <div className={styles.oldPrice}>
              <p>{sale.price}</p>
              <div></div>
            </div>
          ) : null}
          <h3>{price}</h3>
          <div className={styles.discount}>
            {sale ? (
              <>
                <img src={sale.image} alt="sale"></img>
                <p style={{ color: sale.color || '#ffffff' }}>
                  {sale.discount}
                </p>
              </>
            ) : null}
          </div>
        </div>
        <ul>
          {info.map(({ text, isIncluded }, index) => (
            <li
              key={index}
              style={{
                listStyleImage: `url(${isIncluded ? marker : greyMarker})`,
              }}
              className={!isIncluded ? styles.lockedLi : null}
            >
              {text}
            </li>
          ))}
        </ul>
        <div className={styles.info}>
          <div className={styles.access}>
            <p style={{ ...colors[1] }}>{access}</p>
            <p>{language === 'RU' ? 'Доступ' : 'Access'}</p>
          </div>
          <div className={styles.duration}>
            <p style={{ ...colors[1] }}>
              <span>{duration[0]}</span>
              <span>{duration[1]}</span>
            </p>
            <p>{language === 'RU' ? 'Длительность' : 'Duration'}</p>
          </div>
        </div>
      </div>

      <div className={styles.policy}>
        Нажимая "Записаться", Вы подтверждаете
        <a
          href="/documents/Agreement_Data.pdf"
          target="_blank"
          rel="noreferrer"
        >
          {' '}
          согласия{' '}
        </a>
        и принимаете
        <a href="/documents/Oferta.pdf" target="_blank" rel="noreferrer">
          {' '}
          договор оферты{' '}
        </a>
      </div>
      <GradientButton
        color={colors[2]}
        text={language === 'RU' ? 'Записаться' : 'Get'}
        link={path}
      />
    </article>
  );
}
